import { Spinner } from 'react-bootstrap-v5'

interface Props {
    children?: React.ReactNode
    loading: boolean
    className?: string
    height?: string
}
export function Container({children, loading, className,  height = '300'}: Props) {
    return (
        <main className={className}>
            {loading ? (
                <span className={`w-100 d-flex align-items-center justify-content-center h-${height}px`}>
                    <Spinner animation='grow' variant='primary' />
                </span>
            ) : (
                children
            )}
        </main>
    )
}

import { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'

export function DashboardRouter() {
    /*Rotas para Dashboard*/
    const DashboardAdministrative = lazy(
        () => import('../../pages/dashboard/DashboardAdministrative')
    )
    const DashboardFinance = lazy(() => import('../../pages/dashboard/DashboardFinance'))

    return (
        <Switch>
            {/*Rotas para Historico de Atividades*/}
            <Route path='/dashboard/administrativo' component={DashboardAdministrative} />
            <Route path='/dashboard/financeiro' exact component={DashboardFinance} />
        </Switch>
    )
}
